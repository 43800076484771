.searchPopUp {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(250, 250, 250, 0.96);
  z-index: 1000;
  overflow: scroll;
  box-sizing: border-box;
  padding: 10px;
}

.searchPopUp-enter {
  opacity: 0;
}

.searchPopUp-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.searchPopUp-exit {
  opacity: 1;
}

.searchPopUp-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.searchPopUp a {
  text-decoration: underline;
  color: var(--black-color);
}

.searchPopUp a:hover {
  color: var(--color-9f0102);
}

.searchContainer__numResult {
  text-align: center;
}

.searchContainer {
  display: flex;
  flex-direction: column;
}

.searchContainer__close {
  display: grid;
  justify-content: end;
  padding-top: 20px;
  padding-bottom: 0px;
}
