.uploadImageContainer {
  margin-bottom: 10px;
}

.uploadImageContainer img {
  max-height: 150px;
  max-width: 200px;
  object-fit: contain;
  background-color: rgba(0, 0, 0.3);
  border: 2px solid rgba(0, 0, 1);
  border-radius: 10px;
}

.upload-message {
  font-size: 12px;
}
