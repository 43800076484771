.myEvent_card {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.myEvent_card__meta {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.myEvent_card__buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.myEvent_card .btn {
  margin-bottom: 0px;
}

.myEvent_card p,
.myEvent_card h3,
.myEvent_card button {
  margin-bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .myEvent_card {
    display: flex;
    flex-direction: column;
  }
  .myEvent_card__buttons {
    justify-content: center;
  }
}
