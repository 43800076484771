.table {
  /*  display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto, 1fr); */
  margin-bottom: 30px;
}
.tableRow {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
}

.tableColumn {
  border: 1px solid var(--black-color);
  margin: -1px;
  padding: 5px;
}
.tableColumn p {
  margin-bottom: 0px;
}
