.sideNavMobile__outer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  animation: show-outer 0.2s linear forwards;
}

.sideNavMobile__inner {
  width: 0%;
  height: 100%;
  background-color: var(--color-9f0102);
  overflow: auto;
  display: grid;
  animation: show-inner 0.4s linear forwards 0.4s;
  grid-template-rows: 10% 90%;
  max-width: 300px;
}

.sideNavMobile__inner__close {
  justify-self: end;
  padding: 10px;
}

.sideNavMobile__inner__menu {
  padding: 10px;
}

.sideNavMobile__inner__menu__sections {
  margin-bottom: 10px;
  /* -webkit-animation: drive 5s ease; */
}

.sideNavMobile__inner__menu__sections__subBut {
  cursor: pointer;
  color: var(--white-color);
}

@keyframes show-inner {
  100% {
    width: 80%;
  }
}