.login .loaderSpinnerPages img{
    width: 20px;
}

.login .loaderSpinnerPages{
    position: inherit;
    left: 0;
    top: 0;
    transform: inherit;
}

.login .loaderSpinnerPagesContainer {
    position: relative;
    width: 100%;
    min-height: 0px;
}

.login .login_button{
    display: flex;
    flex-direction: row;
}

.loginForm {
    display: grid;
    border-radius: 8px;
    box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -webkit-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -moz-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
}

.login__password {
    display: grid;
   margin-bottom: 10px;
}

.login__password a {
    list-style: none;
    text-decoration: none;
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    color: var(--black-color);
    text-transform: capitalize;
    text-decoration: underline;
    margin-bottom: 5px;
}

.captcha-container p{
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 2px;
    font-size: 14px;
}

.captcha-container .captcha-sum {
    display: flex;
    justify-content: center;
    align-items: center;
}

.captcha-container .captcha-sum span{
    font-size: 34px;
    font-family: var(--font-primary);
    color: #aa2f2e;
}