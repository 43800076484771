footer {
    background-color: var(--color-9f0102);
}

footer .serverTime{
    display: flex;
    justify-content: center;
    color: var(--white-color);
    font-size: 12px;
    padding: 10px 0;
}

footer .columns{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "col1 col2 col3 col4";
    max-width: 1280px;
    margin: 0 auto;
}

footer .columns > div{
    padding: 30px 10px 30px 10px;
    text-decoration: none;
}

footer div:nth-child(1){
    grid-area: col1;
}

footer div:nth-child(2){
    grid-area: col2;
}

footer div:nth-child(3){
    grid-area: col3;
}

footer div:nth-child(4){
    grid-area: col4;
}

footer div h3{
    color: var(--white-color);
    margin-bottom: 15px;
}

footer div ul{
    margin-left: -20px;
}

footer div li{
    list-style: none;
    color: var(--white-color);
    margin-bottom: 5px;
}

footer a{
    color: var(--white-color);
    text-decoration: none;
}

footer a:hover{
    color: var(--white-color);
    text-decoration: underline;
}

footer .socialIcons{
    justify-content: flex-start;
}

footer .facebook-icon,
footer .youtube-icon,
footer .instagram-icon,
footer .twitter-icon{
    color: var(--white-color);
}

@media only screen and (max-width: 800px) {
    footer .columns{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 
            "col1 col2"
            "col3 col4";
    }
}