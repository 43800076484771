.fullWidthBanner{
    text-align: center;
    margin-bottom: 40px;
}
.fullWidthBanner img{
    width: 90%;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.fullWidthBanner img:hover{
    transition: all 500ms;
    box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -webkit-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -moz-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    transform: scale(1.1);
}