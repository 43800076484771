.breadcrumb {
  list-style: none;
  overflow: hidden;
  display: flex;
  padding: 0;
  list-style-type: none;
  max-width: calc(100vw - 80px);
}

.breadcrumb li:first-child {
  margin-left: 0px;
}

.breadcrumb li {
  white-space: nowrap;
}

.breadcrumb li:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb li a {
  color: var(--white-color);
  text-decoration: none;
  padding: 10px 0 10px 25px;
  /* background: brown; */
  background: var(--primary-color);
  position: relative;
}

.breadcrumb li a::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid var(--primary-color);
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumb li a::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid var(--white-color);
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.breadcrumb li:first-child a {
  padding-left: 10px;
}

.breadcrumb li:nth-child(2) a {
  background: var(--primary-color);
}

.breadcrumb li:nth-child(2) a:after {
  border-left-color: var(--primary-color);
}

.breadcrumb li:nth-child(3) a {
  background: var(--primary-color);
}

.breadcrumb li:nth-child(3) a:after {
  border-left-color: var(--primary-color);
}

.breadcrumb li:nth-child(4) a {
  background: var(--primary-color);
}

.breadcrumb li:nth-child(4) a:after {
  border-left-color: var(--primary-color);
}

.breadcrumb li:nth-child(5) a {
  background: var(--primary-color);
}

.breadcrumb li:nth-child(5) a:after {
  border-left-color: var(--primary-color);
}

.breadcrumb li:last-child a {
  background: transparent !important;
  color: var(--black-color);
  pointer-events: none;
  cursor: default;
}

.breadcrumb li:last-child a::after {
  border: 0;
}

.breadcrumb li a:hover {
  background: var(--color-9f0102);
}

.breadcrumb li a:hover:after {
  border-left-color: var(--color-9f0102) !important;
}

/* .breadcrumbs-bullet{
    border: 1px solid var(--black-color);
    padding: 10px;
    position: relative;
}
.breadcrumbs-bullet::after{
    content: '';
	position: absolute;
	left: 0;
	height: 50%;
	width: 100%;
	background: var(--white-color);
	border-left: 2px solid #666;
	border-right: 2px solid #666;
	z-index: -2;
} */
/* .arrow {
    width: 0;
    height: 0;
    top: 0;
    border-style: solid;
    border-width: 20px 0 20px 14px;
    border-color: transparent transparent transparent #000;
    position: absolute;
    right: -15px;
} */

/* .breadcrumb {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	position: relative;
		// Last child
	&:last-child {
		a {
			cursor: default;
		}
		
		&::before,
		&::after {
			background: #ffffc0;
		}
	}
	
	// :Hover
	&:not(:last-child):hover {
		&::before,
		&::after {
			background: lightsalmon;
		}
	}
	
	&::before,
	&::after{
		content: '';
		position: absolute;
		left: 0;
		height: 50%;
		width: 100%;
		background: var(--white-color);
		border-left: 2px solid #666;
		border-right: 2px solid #666;
		z-index: -2;
	}
	
	&::before {
		top: -2px;
		transform: skew(30deg);
		border-top: 2px solid #666;
	}
	
	&::after {
		bottom: -2px;
		transform: skew(-30deg);
		border-bottom: 2px solid #666;
	}
}

a {
	display: inline-block;
	position: relative;
	line-height: 2.5;
	padding: 0 20px;
	color: #666;
	text-decoration: none;
}

li {
	&:first-child {
		background-color: var(--white-color);
		border-left: 2px solid #666;
		left: -5px;
		box-sizing: content-box;

		&:hover {
 			background-color: lightsalmon;			
		}

		&::before,
		&::after {
				left: 5px;


			}
		}
}
 */
