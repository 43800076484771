.imageSlider{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:    
        "leftImageSlider imageSlider imageSlider imageSlider imageSlider imageSlider imageSlider imageSlider imageSlider imageSlider imageSlider rigntImageSlider";
    margin-bottom: 20px;
}

.imageSlider__left{
    grid-area: leftImageSlider;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageSlider__image {
    display: flex;
    gap: 10px;
    overflow: scroll;
    grid-area: imageSlider;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
}

.imageSlider__image__card{
    cursor: pointer;
    transition: all .2s ease-in-out;
    scroll-snap-align: start;
}

/* .imageSlider__image__card:hover{ */
  /*   transition: all 500ms; */
    /* box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -webkit-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -moz-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79); */
   /*  transform: scale(1.1); */
/* } */

.imageSlider__image__card img {
    width: 300px;
    height: 300px;
    object-fit: cover;
}

.imageSlider__right{
    grid-area: rigntImageSlider;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageSlider__right i,
.imageSlider__left i{
    cursor: pointer;
}

.showImageSliderFullScreen__outer{
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

}

.showImageSliderFullScreen__inner{
    width: 60%; 
    background-color: var(--white-color);
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "imageSlider_left imageSlider_image imageSlider_image imageSlider_image imageSlider_image imageSlider_image imageSlider_image imageSlider_image imageSlider_image imageSlider_image imageSlider_image imageSlider_right";
}

.showImageSliderFullScreen__inner__left{
    grid-area: imageSlider_left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.showImageSliderFullScreen__inner__image{
    grid-area: imageSlider_image;
    display: grid;
    grid-template-areas:    
                "sliderHeader"
                "sliderImage"
                "sliderImage"
                "sliderImage"
                "sliderImage"
                "sliderImage"
                "sliderDescription";
}

.showImageSliderFullScreen__inner__image__header{
    grid-area: sliderHeader;
}

.showImageSliderFullScreen__inner__image__image{
    grid-area: sliderImage;
}

.showImageSliderFullScreen__inner img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 400px;
}

.showImageSliderFullScreen__inner__image__image__animation{
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

.showImageSliderFullScreen__inner__image__description{
    grid-area: sliderDescription;
}

.showImageSliderFullScreen__inner__image__description p{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.showImageSliderFullScreen__inner__right{
    grid-area: imageSlider_right;
    display: flex;
    justify-content: center;
    align-items: center;
}

.showImageSliderFullScreen__inner__left i,
.showImageSliderFullScreen__inner__right i{
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .showImageSliderFullScreen__inner{
        width: 100%; 
    }
    .imageSlider__image__card img{
        width: 240px;
        height: 240px;
    }
}