.articleSideNav{
    display: grid;
    grid-gap: 10px;
    padding: 10px;
}
.articleSideNav .articleCard{
    flex-direction: row;
}
.articleSideNav .articleCard__image {
    padding: 5px 0px 5px 5px;
}
.articleSideNav .articleCard__image img{
    max-width: 100px;
}
.articleSideNav .articleCard__meta{
    padding: 5px;
}
.articleSideNav .articleCard__meta h4,
.articleSideNav .articleCard__meta p{
    margin-bottom: 0px;
}
.articleSideNav .socialIcons{
    max-width: 200px;
    margin: auto;
    margin-bottom: 20px;
}
.articleSideNav .btn{
    width: 100%;
}

#mc_embed_signup{
    background:var(--white-color);
    clear:left; 
    font:14px Helvetica,Arial,sans-serif; 
}
