.registerAuth {
  margin-bottom: 100px;
}
/* .password__input {
  position: relative;
}
.password__input__eye {
  border: 1px solid var(--black-color);
  position: absolute;
  top: 0px;
  right: 0px;
  height: 33px;
  width: 33px;
  border-radius: 0px 6px 6px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.password__input__eye:hover {
  border: 1px solid lightgray;
  background-color: lightgray;
} */
.termsCheck {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.termsCheck input {
  width: 13px;
  margin-right: 10px;
}

.termsCheck a{
  color: var(--black-color);
}