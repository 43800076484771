.center80{
    width: 80%;
    margin: 0 auto;
    max-width: 1280px;
}

.center60{
    width: 60%;
    margin: 0 auto;
    max-width: 1280px;
}

.center33{
    width: 33%;
    margin: 0 auto;
    max-width: 1280px;
}

.gridFlex{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.margin-bottom-10{
    margin-bottom: 10px;
}

.margin-bottom-20{
    margin-bottom: 10px;
}

.margin-bottom-30{
    margin-bottom: 10px;
}

.margin-bottom-40{
    margin-bottom: 10px;
}

.margin-bottom-50{
    margin-bottom: 10px;
}

.margin-bottom-60{
    margin-bottom: 10px;
}

.margin-bottom-70{
    margin-bottom: 10px;
}

.margin-bottom-80{
    margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
    .center80{
        width: 90%;
    }
    .center60{
        width: 70%;
    }
    .center33{
        width: 70%;
    }
}

@media only screen and (max-width: 600px) {
    .center80{
        width: 90%;
    }
    .center60{
        width: 90%;
    }
    .center33{
        width: 90%;
    }
}