.slider-toggle {
  width: 60px;
  height: 30px;
  border-radius: 15px;
  background-color: var(--color-ccc);
  position: relative;
  cursor: pointer;
}

.slider {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--white-color);
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
}

.slider-toggle.on {
  background-color: #79d70f;
}

.slider-toggle.off {
  background-color: #e52d2d;
}

.slider-toggle.on .slider {
  transform: translateX(30px);
}

.slider-toggle.off .slider {
  transform: translateX(0);
}

.slider-toggle .toggle-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 14px;
  pointer-events: none;
}

.slider-toggle .toggle-text:first-child {
  left: 5px;
}

.slider-toggle .toggle-text:last-child {
  right: 5px;
}
