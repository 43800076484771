.interval_timer_container{
    margin-top: -30px;
    padding: 30px 0px 30px 0px;
    min-height: calc(100vh - 200px);
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #19364c;
    color: var(--white-color);
}

.interval_timer_container h1{
    font-size: 60px;
    color: var(--white-color);
    text-align: center;
}

.settings_grid4{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.settings_grid4 label{
    justify-content: flex-end;
    display: flex;
}

.settings_grid4 input{
    margin-bottom: 0px;
}

.interval_settings_presets{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.interval_settings_presets button{
    margin-bottom: 0px;
}

.clock_container_outer{
    position: relative;
}

.clock_container{
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #19364c;
    width: 100%;
    height: 100%;
    color: var(--black-color);
}

.clock_container_back{
    display: flex;
    justify-content: center;
}

.clock_container_back .btn{
    font-size: 7vh;
}

.clock{
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

.clock_screen{
    width: 100%;
    height: 65vh;
    /* background-color: grey; */
    margin-bottom: 40px;
    font-size: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-content: center; */
    align-items: center;
}

.clock_screen_time{
    font-family: var(--font-clock);
    line-height: 35vh;
}

.clock_screen_meta{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.clock_screen_meta p{
    font-size: 7vh;
    margin-bottom: 0px;
    font-family: var(--font-primary);
}

.clock_screen_stage p{
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 10vh;
    font-family: var(--font-primary);
}

.clock_buttons{
    gap: 30px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.clock_buttons i{
    font-size: 10vh;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    color: var(--white-color);
}

.clock_buttons i:hover{
    transform: scale(1.5);
}

.clock_buttons button{
    margin-bottom: 0px;
}

.warmupColour{
    background-color: yellow;
}

.activeColour{
    background-color: lawngreen;;
}

.restColour{
    background-color: var(--red-color);
    color: var(--white-color);
}

.endOfRoundColour{
    background-color: var(--red-color);
    color: var(--white-color);
}

.coolDownColour{
    background-color: #8FEFEF;
}

.finishedColour{
    color: var(--white-color);
}

.interval_settings_button{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1300px) {
    .settings_grid4{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .settings_grid4 label{
        justify-content: flex-start;
        display: flex;
    }
}

@media only screen and (max-width: 800px) {
    .clock_screen_meta{
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
}

@media only screen and (max-width: 450px) {
    .clock_screen {
        font-size: 30vh;
    }
}

@media only screen and (max-width: 400px) {
    .settings_grid4{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
    }
    .clock_screen {
        font-size: 24vh;
    }
}