.padding10{
    padding: 10px;
}

.padding20{
    padding: 20px;
}

.padding30{
    padding: 30px;
}