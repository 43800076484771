.exerciseGrid7030{
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas: "exerciseCol17030 exerciseCol27030";
    margin-bottom: 20px;
}

.exerciseGrid7030__col1{
    grid-area: exerciseCol17030;
    padding: 0 25px 0 0;
}

.exerciseGrid7030__col2{
    grid-area: exerciseCol27030;
}

.exerciseArticleContainer{
    margin-bottom: 40px;
}

.exerciseDatabase{
    position: relative;
}

.exercisesLeftNav {
    position: sticky;
    top: 40px;
    height: 100vh;
    min-height: 600px;
}

.exerciseDatabase .grid3070__col1{
    padding: 0px 20px 0px 20px;
}

.exerciseDatabase .grid3070__col2{
    padding: 0px 20px 0px 20px;
}

.filterSearchMobile{
    display: none;
    margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
    .exerciseGrid7030{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 
                    "exerciseCol17030"
                    "exerciseCol27030";
    }
    .exerciseGrid7030__col1{
        grid-area: exerciseCol17030;
        padding: 0;
    }
}

@media only screen and (max-width: 800px) and (min-width: 0px) {
    .exercisesLeftNav {
        display: none;
    }
    .exerciseFilterMobile{
        position: fixed;
        height: 100vh;
        background-color: var(--white-color);
        top: 0%;
        left: 0;
        padding: 30px;
        box-sizing: border-box;
        width: 100%;
        z-index: 50;
    }
    .filterSearchMobile{
        display: block;
    }
}