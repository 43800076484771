.control-panel{
    height: inherit;
    display: grid;
    grid-template-columns: 250px auto;
    margin-top: -30px;
    margin-bottom: 0px!important;
    position: relative;
    overflow: hidden;
}

.control-panel-btn-toggle{
    position: absolute;
    top: 20px;
    left: -1px;
    z-index: 10;
    height: 100px;
    width: 25px;
    border-radius: 0px 10px 10px 0px;
    border: 2px solid var(--black-color);
    border-left: 1px;
    padding: 5px;
    box-sizing: border-box;
    font-size: 25px;
    -webkit-box-shadow: 1px 1px 22px 1px rgba(0,0,0,0.66);
    box-shadow: 1px 1px 22px 1px rgba(0,0,0,0.66);
    cursor: pointer;
    background-color: var(--white-color);
}

.control-panel-btn-toggle:hover{
    filter: invert(100%);
}

.control-panel-collapsed {
    grid-template-columns: 0 auto;
}

.control-panel-left{
    background-color: var(--white-color);
}

.control-panel-center{
    position: relative;
    overflow: auto;
} 

.control-panel-right{
    padding: 10px;
    background-color: #19364c;
}

.control-panel-right h3,
.control-panel-left h3{
    padding: 10px;
    margin: 0;
    text-decoration: underline;
}

.cp_current_goal_wrapper{
    max-width: 400px;
    margin: 0 auto;
}

.control_panel_current_goal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    padding: 10px;
    margin-bottom: 20px;
    overflow: scroll;
   
    max-width: 320px;
    min-width: 250px;


    background-color: var(--white-color);
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.control_panel_current_goal p,
.control_panel_current_goal h3{
    margin-bottom: 5px;
    color: var(--black-color);
}

.controlPanelCenter{
    position: relative;
    padding: 10px 20px 20px 30px;
    margin: 0 auto;
    max-width: 1200px;
}

.controlPanelLeft{
    min-height: calc(100vh - 200px);
    border-right: 2px solid var(--black-color);
    height: 100%;
}

/* .controlPanelLeft-mobile{
    display: none;
} */

.goal-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

/* @media only screen and (max-width: 800px) {
    .controlPanelLeft{
        display: none;
    } */
  /*   .controlPanelLeft-mobile{
        display: block;
    } */
/* } */

.control-panel-sub{
        margin: 0 auto;
        max-width: 1280px;
}