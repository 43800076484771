.message__container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: "messageList chatScreen chatScreen chatScreen profileCard";
    gap: 5px;
    min-height: 460px;
    margin-bottom: 20px;
    overflow: hidden;
}

.messageChatBox button {
    margin: 0;
}

.messageChatBox-row{
    display: flex;
    align-items: flex-end;
    gap: 5px;
    margin-bottom: 5px;
}

.messageChatBox__input__character_limit{
    display: flex;
    justify-content: flex-end;
}

.messageChatBox__input__character_limit p{
    font-size: 10px;
    margin-bottom: 5px;
}

.messageChatBox__screen__message{
    border-radius: 10px;
    padding: 10px;
    max-width: 70%;
}

.message__placeholder {
    grid-column: span 4;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 0 10px 0  0;
    font-size: 75px;
}

.messageChatBox__screen__message__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.messageChatBox__screen__message__date{
    font-size: 11px;
}

.messageChatBox__screen__message p{
   margin-bottom: 5px;
}

.messageChatBox__screen__message__profile-image{
    background-color: var(--primary-color);
    width: 28px;
    height: 28px;
    border-radius: 50px;
    margin-left: auto;
}

.messageChatBox__screen__message__profile-image_other_person{
    background-color: var(--primary-color);
    width: 28px;
    height: 28px;
    border-radius: 50px;
}

.messageSender{
    color: var(--white-color);
    background-color: rgb(0, 132, 255);
}

.messageReceiver{
    background-color: #e4e6eb;
    margin-right: auto;
}

.messageList {
    grid-area: messageList;
    overflow: hidden;
    height: 100%;
}

.messageList_messages {
    height: 100%;
    overflow-x: auto;
    /* overflow-x: hidden; */
    max-height: 200px;
}

@media only screen and (min-width: 800px) {
    .messageList {
        grid-area: messageList;
        padding: 0 0 0 10px;
    }
    .messageList_messages {
        height: 100%;
        overflow-x: auto;
        /* overflow-x: hidden; */
        max-height: 500px;
    }
}

.messageChatBox{
    grid-area: chatScreen;
    overflow: hidden;
    word-wrap: break-word;
    margin: 0;
}

.messageProfileCard{
    grid-area: profileCard;
}

.messageChatBox .messageChatBox__screen {
    height: calc(100vh - 300px);
    overflow-x: hidden;
 
    background-color: var(--white-color);
   
    margin-bottom: 10px;
    padding: 10px 10px;
    position: relative;

    border-radius: 8px;
    border:  1px solid #e0e0e0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}
/* .messageChatBox__screen__inner {
    overflow-y: scroll;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}  */

@media only screen and (max-width: 800px) {
    .message__container{
        height: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            "messageList"
            "chatScreen"
            "profileCard";
        gap: 20px;
        padding: 20px;
    }
}

.messageNotification{
    margin-bottom: 5px;
    border: 1px solid var(--primary-color);
    border-radius: 15px;
    padding: 10px;
    margin: 0 auto 10px;
    font-size: 14px;
    max-width: 700px;
}

@media (max-width: 767px) {
    .messageNotification{
    margin: 0  10px 10px;
    }
}

.messageNotification h4{
    margin-bottom: 5px;
}

.messageNotification p{
    margin: 0;
    font-weight: bold;
}
