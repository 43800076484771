.companyMessagePopUp{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.companyMessagePopUp__inner{
    background-color: #19364c;
    width: 70%;
   /*  height: 300px; */
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
    .companyMessagePopUp__inner{
        width: 90%;
    }
}