* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
.closeButton{
    display: flex;
    justify-content: flex-end;
}
.closeButton button{
    background-color: var(--white-color);
    border: none;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
.closeButton button:hover{
    transform: scale(1.5);
}
.backgroundGrey{
    background-color: #F4F4F4;
}
.calender__hour.backgroundColour{
    background-color: transparent;
}
.displayNone{
    display: none;
}
.displayGrid{
    display: grid;
}

.displayGridThree{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
    gap: 20px;
    max-width: 1260px;
    margin: 0 auto 20px;
}

.displayGridThree img{
    width: 100%;
}
.displayFlexRow{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.displayFlexRowSpaceBetween{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
}

.closeContainer {
    display: flex;
    justify-content: end;
}
.closeContainer .close{
    cursor: pointer;
}

.flexRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/*  Headers */

h1 {
    color: #aa2f2e;
    text-transform: uppercase;
    font-size: 34px;
    margin-bottom: 20px;
    font-family: var(--font-primary);
    max-width: 100vw;
}   
h2 {
    color: #A00000;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-family: var(--font-primary);
    font-size: 26px;
    text-decoration: underline;
}

h3, h4, h5 {
    color: var(--black-color);
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: var(--font-primary);
}
h3 {
    font-size: 20px;
}
h4 {
    font-size: 18px;
}
h5 {
    font-size: 16px;
}
p {
    font-family: var(--font-secondary);
    margin-bottom: 20px;
}
span{
    font-family: var(--font-secondary);
  /*   margin-bottom: 20px; */
}
label {
    font-family: var(--font-secondary);
   /*  margin-bottom: 5px; */
}
a {
    color: #aa2f2e;
    text-decoration: none;
    font-weight: 600;
}

/* Lists */

ul, ol {
    margin-bottom: 20px;
}
li {
    font-family: var(--font-secondary);
    margin-left: 20px;
}
.pointer{
    cursor: pointer;
}

/* Buttons */
.btn {
    cursor: pointer;
    border: 2px solid var(--black-color);
    padding: 5px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    display: block;
    border-radius: 6px;
    font-family: var(--font-primary);
    font-size: 30px;
    transition: all 0.5s ease-in-out;
}
.btn:hover{
    transform: scale(1.05);
}

.btn span {
    font-family: var(--font-primary);
}
.primary{
    color: var(--white-color);
    background-color: var(--primary-color);
}
.primary:hover{
    transition: all 500ms;
    color: var(--white-color);
    background-color: #510000;
}
.primary:disabled {
    opacity: 0.5; 
    transition: none;
    transform: none;
    cursor: not-allowed;
}
.primary:disabled:hover {
    opacity: 0.5; 
    transition: none;
    transform: none;
    cursor: not-allowed;
    background-color: var(--primary-color);
}
.secondary{
    color: var(--primary-color);
    background-color: var(--white-color);
    border: 1px solid var(--primary-color);
}

.btn_secondary_selected{
     background-color: #008080; 
     color: var(--white-color);
}

.secondarySelected.secondary {
    opacity: 0.8; 
    box-shadow: 0 0 5px rgba(1, 157, 157, 0.5);
    background-color: #008080;
}
.delete{
    color: var(--white-color);
    background-color: var(--red-color);
    border: 1px solid var(--primary-color);
    cursor: pointer;
}
.errorMsg{
    color: var(--red-color);
    text-transform: uppercase;
}
.delete:hover{
    background-color: #510000;
}
.secondary:hover{
    transition: all 500ms;
    color: var(--white-color);
    background-color: #511F00;
}
.tertiary {
    color: var(--white-color);
    background-color: #249078;
}
.tertiary:hover {
    transition: all 500ms;
    color: var(--white-color);
    background-color: #5e706c;
}

.btn__tag {
    border: 2px solid var(--color-9f0102);
    border-radius: 20px;
    font-size: 13px;
    padding: 3px 10px 3px 10px;
    min-width: 50px;
    text-align: center;
    margin-bottom: 20px;
    background-color: var(--white-color);
    text-transform: uppercase;
}

.global__cardLink {
    text-decoration: none;
    color: var(--black-color);
}

.hr_divider{
    margin-bottom: 20px;
}

.loader{
    position: absolute;
    top: 50%;
    left: 50%;
}
.loaderSpinner{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loaderSpinnerPagesContainer{
    position: relative;
    width: 100%;
    min-height: 400px;
}

.loaderSpinnerPages{
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 800px) {
    h1 {
        font-size: 32px;
    }   
    .mobile_image{
        width: 100%;
    }
    .displayGridThree{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 600px) {
    .displayGridThree{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}