.grid4060-filtercomp{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas: "template40_col1 template40_col1 template40_col1 template40_col1 template60_col2 template60_col2 template60_col2 template60_col2 template60_col2 template60_col2";
    margin-bottom: 20px;
}

.grid4060-filtercomp img{
    width: 100%;
}

.grid4060__col1-filtercomp{
    grid-area: template40_col1;
} 

.grid4060__col2-filtercomp{
    grid-area: template60_col2;
} 

@media only screen and (max-width: 800px) {
    .grid4060-filtercomp{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 
            "template60_col2"
            "template40_col1";
    }
}

.filter-company{
    background-color: #19364c;
    margin-top: -30px;
    padding: 30px 0px 30px 0px;
}

.filter-company h1{
    font-size: 60px;
    color: var(--white-color);
    text-align: center;
}

.filter-company-search .searchBox {
    margin-bottom: 50px;
}

.filter-company form label{
    color: var(--white-color);
}

.filter-company form select {
    border-radius: 10px;
    padding: 10px;
}

@media only screen and (max-width: 800px) and (min-width: 0px) {
    .filter-company h1{
        font-size: 45px;
    }
}