/* global.css */
:root {
    --primary-color: #019D9D; /* 70 */
    --white-color: #FFF; /* 216 */
    --black-color: #000; /* 94 */
    --red-color: #FF0000; /* 25 */

    --primary-blue: #3498db; /* 20 */
    --primary-green: #2ecc71; /* 1 */
    --primary-red: #d9534f; /* 3 */
     /* === Extended Colors === */
    --color-51a9a9: #51a9a9; /* 0 */
    --color-orange: orange; /* 0 */
    --color-green: green; /* 0 */
    --color-teal: teal; /* 0 */
    --color-blue: blue; /* 0 */
    --color-grey: grey; /* 0 */
    --color-darkred: darkred; /* 0 */
    --color-c2787f: #c2787f; /* 0 */
    --color-f29b9c: #f29b9c; /* 0 */
    --color-6d0102: #6d0102; /* 0 */
    --color-9f0102: #9f0102; /* 0 */
    --color-8c0102: #8c0102; /* 0 */
    --color-f0ad4e: #f0ad4e; /* 0 */
    --color-5bc0de: #5bc0de; /* 0 */
    --color-5cb85c: #5cb85c; /* 0 */
    --color-6c757d: #6c757d; /* 0 */
    --color-d8d8d8: #d8d8d8; /* 2 */
    --color-009d9d: #009d9d; /* 0 */
    --color-008c8c: #008c8c; /* 1 */
    --color-ffdd57: #ffdd57; /* 1 */
    --color-ffc107: #ffc107; /* 2 */
    --color-28a745: #28a745; /* 1 */
    --color-218838: #218838; /* 1 */
    --color-5a6268: #5a6268; /* 1 */
    --color-e0a800: #e0a800; /* 0 */
    --color-c9302c: #c9302c; /* 0 */
    --color-17a2b8: #17a2b8; /* 0 */
    --color-138496: #138496; /* 0 */
    --color-343a40: #343a40; /* 0 */
    --color-23272b: #23272b; /* 0 */
    --color-ff5733: #ff5733; /* 0 */
    --color-c70039: #c70039; /* 0 */
    --color-19364c: #19364c; /* 0 */
    --color-ccc: #ccc; /* 11 */
    --color-f7b924: #f7b924; /* 1 */
    --color-6f42c1: #6f42c1; /* 0 */
    --color-d8a200: #d8a200; /* 0 */
    --color-029408: #029408; /* 0 */
    --color-f0f0f0: #f0f0f0; /* 0 */
    --color-f40404: #f40404; /* 0 */
    --color-e8f5e9: #e8f5e9; /* 0 */
    --color-388e3c: #388e3c; /* 0 */
    --color-ffebee: #ffebee; /* 0 */
    --color-d32f2f: #d32f2f; /* 0 */
    --color-555: #555; /* 0 */
    --color-A00000: #A00000; /* 0 */
    --color-d7d7d7: #d7d7d7; /* 0 */
    --color-007bff: #007bff; /* 0 */
    --color-0078d4: #0078d4; /* 0 */
    --color-f3f3f3: #f3f3f3; /* 0 */
    --color-f5f5f5: #f5f5f5; /* 0 */
    --color-e5e5e5: #e5e5e5; /* 0 */
    --color-57a4a4: #57a4a4; /* 0 */
    --color-4e4e4e: #4e4e4e; /* 0 */
    --color-eaf7ff: #eaf7ff; /* 0 */
    --color-fff4e5: #fff4e5; /* 0 */
    --color-067D62: #067D62; /* 3 */
    --color-333: #333; /* 4 */
    --color-222: #222; /* 3 */
    --color-cacaca: #cacaca; /* 1 */
    /* === Primary Font (Main UI Text) === */
    --font-primary: VeneerThree, Helvetica, sans-serif;
    /* === Secondary Font (Headings, Accents) === */
    --font-secondary: arial, sans-serif;
    /* === Clock Font === */
    --font-clock: Arial Black, Arial Black, Gadget, sans-serif;
    /* === Button Font === */
    --font-button: Trebuchet MS;
  }