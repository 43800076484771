.main-video-page {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.video-chat-wrapper { 
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#large-feed {
  background-color: var(--black-color);
  height: 100vh;
  width: 100vw;
  transform: scaleX(-1);
}

#own-feed {
  position: absolute;
  border: var(--white-color) 1px solid;
  right: 50px;
  top: 50px;
  border-radius: 10px;
  width: 320px;
}

.chat-window {
  position: absolute;
  right: -25%;
  height: 100px;
  transition: all 1s;
  z-index: 1000;
  width: 25%;
  top: 0;
  height: 100vh;
}

#menu-buttons {
  height: 80px;
  width: 100%;
  background-color: var(--color-333);
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
}

#menu-buttons .fa {
  font-size: 24px;
}

.audioVideoButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.videoOptionButtons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.videoPopup,
.dialingPopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.videoPopup__inner {
  width: 100%;
  max-height: 100vh;
  background-color: var(--white-color);
}

.dialingPopup__inner {
  width: 100px;
  max-height: 100px;
  background-color: var(--black-color);
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoPopup__inner,
.dialingPopup__inner {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  overflow: scroll;
}

@media only screen and (max-width: 800px) {
  .videoPopup__inner {
    width: 95%;
    max-height: 95vh;
    padding: 10px;
  }
}

.dialingPopup__inner i {
  /* Your existing styles */
  font-size: 60px; /* Adjust as needed */
  color: var(--white-color); /* Adjust the color as needed */

  /* Animation */
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.hangUp {
  background-color: var(--red-color);
  color: var(--white-color);
  font-size: 20px;
  border-radius: 5px;
  border: 0px solid var(--black-color);
  padding: 5px;
  min-width: 150px;
  cursor: pointer;
}

.hangUp:hover {
  background-color: #890101;
}

.video-button {
  color: var(--white-color);
  cursor: pointer;
}

.video-button:hover {
  background-color: blue;
}
