.add-company-section-number{
    display: flex;
    flex-direction: row;
    max-width: 80%;
    margin: auto;
    justify-content: center;
    gap: 20px;
    font-family: var(--font-primary);
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.add-company-section-number div{
    width: 50px;
    height: 50px;
 /*    background-color: var(--primary-color);
    color: var(--white-color); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 2px solid var(--black-color);
    border-radius: 50%;
    font-size: 30px;
    cursor: pointer;
}
.add-company-section-number div:hover{
    cursor: not-allowed;
}
.selectDay__container{
    margin-bottom: 20px;
}
.selectDay{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    justify-items: baseline;
    align-items: baseline;
    margin-bottom: 10px;
}
.selectDay .btn{
    margin-bottom: 0;
    font-size: 16px;
}

.selectDay input{
    width: auto;
}


.companyType{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 10px;
    margin-bottom: 20px;
}
 .companyType:hover,
.companyType:hover > span{
    cursor: pointer;
    transition: all 500ms;
    box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -webkit-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -moz-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    border-radius: 20px;
}

.companyType__number span{
    font-family: var(--font-primary);
    color: var(--primary-color);
    font-size: 60px;
}
.companyType_selected,
.companyType_selected > span{
    color: var(--white-color);
    cursor: pointer;
    background-color: var(--primary-color);
    border-radius: 20px;
}
.companyType_selected span,
.companyType_selected h3{
    color: var(--white-color);
}


@media only screen and (max-width: 600px) {
    .add-company-section-number{
        max-width: 90%;
    }
    .add-company-section-number div {
        width: 30px;
        height: 30px;
        font-size: 22px;
    }
}