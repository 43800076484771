.calender_dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 50px;
  overflow: scroll;
  background-color: #f0f0f0; /* Light background color */
  background-image: repeating-linear-gradient(
    45deg,
    /* Angle for diagonal lines */ rgba(0, 0, 0, 0.1),
    /* Line color */ rgba(0, 0, 0, 0.1) 1px,
    /* Line thickness */ transparent 2px,
    /* Space between lines */ transparent 10px /* Line spacing */
  );
  border-radius: 10px;
}

.calender_dates h4 {
  margin-bottom: 0px;
}

.calender_dates_column {
  min-width: max-content;
  margin: 0 2px 0 0;
}

.calender_dates_column:last-child {
  margin: 0;
}

.calender_dates_column.disabled {
  background-color: #f0f0f0; /* Light background color */
  background-image: repeating-linear-gradient(
    45deg,
    /* Angle for diagonal lines */ rgba(0, 0, 0, 0.1),
    /* Line color */ rgba(0, 0, 0, 0.1) 1px,
    /* Line thickness */ transparent 2px,
    /* Space between lines */ transparent 10px /* Line spacing */
  );
  border: 1px solid #00000026;
  min-width: 40px;
  border-radius: 10px;
}

.calender_overview {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.calender_overview__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.calender_overview h3,
.calender_overview button {
  margin-bottom: 5px;
}

.calender__day__today {
  background: linear-gradient(
    to bottom,
    #b00000 5%,
    #7a0000 100%
  ); /* Gradient with lighter and darker shades */
  background-color: #b00000; /* Base background color */
  border: 1px solid #7a0000; /* Darker border to match the gradient */
  color: var(--white-color);
  text-shadow: 0px 1px 0px #4f0000;
  padding: 10px;
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  margin-bottom: 2px;
  text-align: center;
}

.calender__day__today h4 {
  color: var(--white-color);
  margin: 0;
  font-size: 30px;
}

/* .calender__day{
    background-color: lightblue;
    border: 2px solid var(--black-color);
    padding: 10px;
    border-radius: 10px;
} */

.calender__hour {
  background-color: var(--white-color);
  border: 1px solid var(--black-color);
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 2px;
  height: 30px;
}

.calender__hour:not(.calender__hour.backgroundColour):hover {
  background-color: lightblue;
  cursor: pointer;
}

.calender__hour.backgroundColour:hover {
  cursor: not-allowed;
}

.calender__hour__past {
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--white-color);
  border: 1px solid var(--black-color);
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 2px;
}

.calender__hour,
.calender__day,
.calender__day__today,
.calender__hour__past {
  font-family: var(--font-secondary);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.calendar_popUp__outter {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
}

.calendar_popUp__inner {
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid var(--primary-color);
  max-height: 100vh;
  overflow: scroll;
}

@media only screen and (min-width: 600px) {
  .calendar_popUp__inner {
    width: 50%;
  }
}

.calendar_popUp__inner select {
  margin-bottom: 0px;
  width: auto;
  height: 40px;
}

.calendar_owner_card {
  display: flex;
  flex-direction: row;
  /*   height: 80px; */
  gap: 10px;

  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
  justify-content: center;
  cursor: pointer;

  background-color: var(--white-color);
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.calendar_owner_card:hover {
  box-shadow: 5px 5px 20px 5px rgb(0 0 0 / 20%);
}

.calendar_owner_card h4 {
  margin-bottom: 10px;
}

.calendar_owner_card p {
  margin-bottom: 0px;
}

.calendar_owner_card img {
  height: 80px;
  width: 100%;
  object-fit: contain;
}

.calendar_owner_card__meta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.calendar_owner_card__meta__startEnd {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.editCalendarOptions {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
  border-radius: 10px;

  background-color: var(--white-color);
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.editCalendarOptions .btn {
  font-size: 22px;
  margin-bottom: 0px;
}

.appointmentCardOwner {
  position: absolute;
  top: -1px;
  left: 0px;
  background-color: var(--primary-color);
  z-index: 5;
  border: 1px solid var(--black-color);
  color: var(--white-color);
  /* text-transform: uppercase; */
  overflow: hidden;
  width: 50%;
  cursor: pointer;
  padding: 3px 0px 0px 3px;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
}

.appointmentCardOwner:hover {
  background-color: #05d2d2;
}

.appointmentCardNotOwner {
  position: absolute;
  top: -1px;
  left: -1px;
  background-color: lightblue;
  z-index: 5;
  border: 1px solid var(--black-color);
  color: var(--white-color);
  /* text-transform: uppercase; */
  overflow: hidden;
  width: 50%;
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  cursor: not-allowed;
}

@media only screen and (max-width: 600px) {
  .calendar_owner_card__meta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .calendar_owner_card__meta__startEnd {
    flex-direction: row;
    justify-content: center;
  }
  .calendar_owner_card {
    flex-direction: column;
  }
}

.allBreaksDayOfWeek {
  margin: 0 0 20px;
}

.hourContainer {
  overflow: hidden;
}

/*.hourContainer_section{
    height: 30px;
    text-align: center;
    cursor: pointer;
    line-height: 30px;
    border: 2px solid var(--black-color);
    border-radius: 10px;
    background-color: var(--white-color);
    position: relative;
}

.hourContainer_section:hover{
    filter:invert();
}*/

.hourContainer_section,
.calender__day {
  height: 30px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  font-family: var(--font-secondary);
  font-size: 19px;
  padding: 4px 37px;
  text-decoration: none;
  margin-bottom: 2px;
  text-align: center;
}

.hourContainer_section {
  background: linear-gradient(
    to bottom,
    var(--color-009d9d) 5%,
    #017575 100%
  ); /* Gradient with lighter and darker shades */
  background-color: #33bcbc; /* Base background color */
  border: 1px solid #017575; /* Darker border to match the gradient */
  color: var(--white-color);
  text-shadow: 0px 1px 0px #004c4c; /* Darker text shadow for contrast */
}

.calender__day {
  background: linear-gradient(
    to bottom,
    var(--color-009d9d) 5%,
    #017575 100%
  ); /* Gradient with lighter and darker shades */
  background-color: #33bcbc; /* Base background color */
  border: 1px solid #017575; /* Darker border to match the gradient */
  color: var(--white-color);
  text-shadow: 0px 1px 0px #004c4c; /* Darker text shadow for contrast */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}

.calender__day h4 {
  color: var(--white-color);
  margin: 0;
  font-size: 30px;
}

.hourContainer_section:hover {
  background: linear-gradient(to bottom, #476e9e 5%, #7892c2 100%);
  background-color: #476e9e;
}

.hour_percentage {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  background-color: var(--black-color);
  opacity: 0.5;
}
