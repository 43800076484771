.ps-imageGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-bottom: 30px;
}

.ps-imageGrid-container {
  cursor: pointer;
  height: fit-content;
}

.ps-imageGrid-container:hover {
  transition: all 500ms;
  box-shadow: 1px 1px 24px -1px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: 1px 1px 24px -1px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: 1px 1px 24px -1px rgba(0, 0, 0, 0.79);
}

.ps-imageGrid-image {
  width: 100%;
}
