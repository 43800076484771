.articleGrid7030{
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas: "articleCol17030 articleCol27030";
    margin-bottom: 20px;
}

.articleGrid7030__col1{
    grid-area: articleCol17030;
    padding: 0 25px 0 0;
}

.articleGrid7030__col2{
    grid-area: articleCol27030;
}

.article_body{
    display: flex;
    flex-direction: column;
    max-width: 90vw;
}

.article_body img{
    margin: 0 auto;
    max-width: 50%;
    margin-bottom: 30px;
    -webkit-box-shadow: 3px 3px 7px rgb(0 0 0 / 30%);
    box-shadow: 3px 3px 7px rgb(0 0 0 / 30%);
}
.articleTable__container{
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    overflow: scroll;
}
.articleTable__table{
    width: 100%;
    min-width: 600px;
    margin-bottom: 10px;
}
.articleTable__table thead{
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 5px; 
}
.articleTable__table td {
    padding: 2px 5px;
}
.article__author{
    display: grid;
    grid-template-columns: 30% 70%;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 40px;
    max-width: 400px;
    gap: 20px;
}
.article__author__meta{

}
.article__author__meta__links{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.article__author__image{
    display: flex;
    justify-content: center;
    align-items: center;
}
.article__author__image img{
    max-width: 100px;
    width: 100%;
    border-radius: 50%;
}

@media only screen and (max-width: 800px) {
    .articleGrid7030{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 
                    "articleCol17030"
                    "articleCol27030";
    }
    .articleGrid7030__col1{
        padding: 0;
    }
}

@media only screen and (max-width: 600px) and (min-width: 0px) {
    .article_body img{
        max-width: 90%;
    }
}