.categoryCard,
.productCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  /*     border: 1px solid var(--primary-color); */
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 10px;
}

.categoryCard:hover,
.productCard:hover {
  transition: all 500ms;
  box-shadow: 1px 1px 24px -1px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: 1px 1px 24px -1px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: 1px 1px 24px -1px rgba(0, 0, 0, 0.79);
}

.categoryCard img {
  margin-bottom: 10px;
  border-radius: 10px;
  /*   height: 300px; */
  object-fit: cover;
}

.categoryCard .category_card_meta {
  flex-direction: row;
  gap: 20px;
  justify-content: start;
}

.categoryCard h3 {
  font-size: 30px;
  margin-bottom: 0px;
}

.categoryCard p {
  font-family: var(--font-primary);
  font-size: 30px;
  margin-bottom: 0px;
}

.productTopOverview {
  padding: 0px 10px;
}

.productTopOverview img.amazon-logo {
  max-width: 150px;
}

.shopFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.shopFilter form {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: end;
}

.shopFilter .displayFlexRow {
  align-items: center;
}

.shopFilter input {
  width: 300px;
}

.shopFilter .btn,
.shopFilter input,
.shopFilter select {
  margin-bottom: 0px;
}

.productOverviewGrid4060 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-areas: "productOverViewTemplate40_col1 productOverViewTemplate40_col1 productOverViewTemplate40_col1 productOverViewTemplate40_col1 productOverViewTemplate60_col2 productOverViewTemplate60_col2 productOverViewTemplate60_col2 productOverViewTemplate60_col2 productOverViewTemplate60_col2 productOverViewTemplate60_col2";
  margin-bottom: 20px;
}

.productOverviewGrid4060 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.productOverviewGrid4060__col1 {
  grid-area: productOverViewTemplate40_col1;
  padding: 20px;
}

.productOverviewGrid4060__col2 {
  grid-area: productOverViewTemplate60_col2;
}

.productOverview_Description {
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) and (min-width: 0px) {
  .shopFilter {
    flex-direction: column;
  }
  .shopFilter input {
    width: 100%;
  }
  .shopFilter form {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .productOverviewGrid4060 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "productOverViewTemplate40_col1"
      "productOverViewTemplate60_col2";
  }
}
