.cookieNotification{
    position: fixed;
    bottom: 5px;
    left: 0px;
    background-color: rgba(51, 51, 52, 0.92);
    color: var(--white-color);
    padding: 40px;
    z-index: 1000;
    box-sizing: border-box;
    border-left: 5px solid var(--primary-color);
    animation: name-of-animation 1s;
}

.cookieNotification h3,
.cookieNotification a {
    color: var(--white-color);
}

@keyframes name-of-animation {
    0%    { opacity: 0; }
    100%   { opacity: 1; }
  }