/* #################### Event ############################ */
.event-filter{
    margin-top: -30px;
    padding: 30px 0px 30px 0px;
    min-height: 80vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.event-filter h4{
    color: var(--white-color);
}

/* #################### Event ############################ */
.event-hero{
    width: 100%;
    height: 400px;
    object-fit: fill;
    margin-bottom: 30px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.event-hero button{
    max-width: 200px;
}

.event-overview-col1{
    padding: 10px;
}

.event-overview-col2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.event-overview-col2 img{
    max-width: 300px;
}

.event-contact-col1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.event-contact-col1 img{
    width: 100%;
    max-width: 300px;
}

.event-contact-col2{
    padding: 10px;
}

.event-location-col1{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.eventCard{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    background-color: var(--white-color);
}

.event-map-cards .eventCard{
    flex-direction: row;
}

.eventCard:hover{
    transition: all 500ms;
    box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -webkit-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -moz-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
}

.eventCard__image{
    padding: 10px;
    display: flex;
    justify-content: center;
}

.eventCard__image img{
    width: 100%;
    object-fit: contain;
}

.eventCard__meta{
    padding: 10px;
    display: flex;
}

.eventCard__meta__date{
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    align-items: center;
}

.eventCard__meta__date_day{
    font-size: 28px;
    margin-bottom: 0px;
}

.eventCard__meta__date_month{
    color: #70757a;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
}

.eventCard__meta__intro{
    
}

.cancel-event-msg{
    margin-bottom: 10px;
}

.cancel-event-cta{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
    grid-gap: 20px;
    margin-bottom: 10px;
    justify-content: center;
}

.cancel-event-cta .btn{
    margin: 0;
}