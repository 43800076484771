.cartTotalContainer{
    position: relative;
    font-size: 35px;
    color: var(--black-color);
    /* border: 1px solid var(--black-color); */
    border-radius: 50%;
    /* line-height: 30px; */
    /* width: 40px; */
    box-sizing: border-box;
}

.cartTotalDiv{
    position: absolute;
    top: 0px;
    right: -5px;
    background-color: #f40404;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    font-size: 10px;
    color: var(--white-color);
    text-align: center;
    line-height: 20px;
}