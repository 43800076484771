.cart{

}
.cart__subtotal{
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.cartProductCard{
   /*  border: 1px solid var(--primary-color);
    border-radius: 20px; */
  /*   padding: 20px; */
    display: grid;
    grid-template-areas: "cartCardImage cartCardMeta cartCardMeta cartCardMeta cartCardTotal";
    grid-template-columns: repeat(5, 1fr);



    padding: 15px 20px;
    margin: 10px 0;
    background-color: var(--white-color);
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    max-width: 800px;

} 
.productCard img {
    border-radius: 10px;
    margin-bottom: 20px;
    /* height: 250px; */
    object-fit: contain;
}
.productCard .productCard_title{
    max-width: 370px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    white-space: normal;
    text-overflow: ellipsis;
}
.cartProductCard__image{
    grid-area: cartCardImage;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cartProductCard__image img{
    width: 100%;
}
.cartProductCard__meta{
    grid-area: cartCardMeta;
    padding: 20px;
}
.cartProductCard__meta__options{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}
.cartProductCard__meta__options button,
.cartProductCard__meta__options select{
    margin-bottom: 0px;
}

.cartProductCard__meta__options .select {
    max-width: 60px;
    margin-bottom: 0px;

}    
.cartProductCard__meta__options .select select {
        font-family: var(--font-secondary);
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 5px 10px;
        outline: 0;
        border: 1px solid var(--black-color);
        border-radius: 100px;
        background: #e6e6e6;
        color: #7b7b7b;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    .cartProductCard__meta__options .select select::-ms-expand {
            display: none;
        }
        .cartProductCard__meta__options .select select:hover,
        .cartProductCard__meta__options .select select:focus {
            color: var(--black-color);
            background: var(--color-ccc);
        }
        .cartProductCard__meta__options .select select:disabled {
            opacity: 1;
            pointer-events: none;
        }
  /*       .cartProductCard__meta__options .select_arrow {
    position: absolute;
    top: 13px;
    right: 14px;
    pointer-events: none;
    border-style: solid;
    border-width: 8px 5px 0px 5px;
    border-color: #7b7b7b transparent transparent transparent;
}
.cartProductCard__meta__options .select select:hover ~ .select_arrow,
.cartProductCard__meta__options .select select:focus ~ .select_arrow {
    border-top-color: var(--black-color);
}
.cartProductCard__meta__options .select select:disabled ~ .select_arrow {
    border-top-color: var(--color-ccc);
} */
.cartProductCard__meta__details h3,
.cartProductCard__image img{
    cursor: pointer;
}
.cartProductCard__total{
    grid-area: cartCardTotal;
    padding: 20px;
}

.cartSideBar{
    min-height: calc(100vh - 300px);
    padding: 0px 20px;
}


@media only screen and (max-width: 600px) {

.cartProductCard{
    border: 1px solid var(--primary-color);
    border-radius: 20px;
  /*   padding: 20px; */
    display: grid;
    grid-template-areas: 
        "cartCardImage cartCardImage"
        "cartCardMeta cartCardMeta"
        "cartCardTotal cartCardTotal";
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 10px;
} 

}