.bft__2{
    display: flex;
    margin-bottom: 20px;
    gap: 30px;
}

/* .bft__2_gender{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.bft_fatTable{
    display: grid;
    grid-template-columns: 50% 25% 25%;
} */

/* @media only screen and (max-width: 800px) {
    .bft__2 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
} */