.grid2080{
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "col1205030 col2205030";
    margin-bottom: 20px;
}

.grid2080__col1{
    grid-area: col1205030;
}

.grid2080__col2{
    grid-area: col2205030;
}

@media only screen and (max-width: 800px) {
    .grid2080{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 
                    "col1205030"
                    "col2205030"
    }
}