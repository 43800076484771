
.dropzone{
    border: 2px dashed var(--black-color);
    border-radius: 8px;    
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.dropzone p {
    margin: 0;
}

.dropzone i {
    font-size: 30px;
}