.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.StripeStarter {
  max-width: 1000px;
  margin: 0 auto;
}

.paymentGrid {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-areas: "paymentGridCol17030 paymentGridCol27030";
  margin-bottom: 20px;
}

.paymentGrid__col1 {
  grid-area: paymentGridCol17030;
  padding-right: 20px;
}

.paymentGrid__col2 {
  grid-area: paymentGridCol27030;
}

@media only screen and (max-width: 800px) {
  .paymentGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "paymentGridCol17030"
      "paymentGridCol27030";
  }
}
