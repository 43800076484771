.gridTrainerSearchWithMap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "trainerSearch trainerMap trainerMap trainerMap"
    "trainerSearch trainerMap trainerMap trainerMap"
    "trainerSearch trainerMap trainerMap trainerMap"
    "trainerSearch trainerCards trainerCards trainerCards";
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .gridTrainerSearchWithMap {
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    grid-template-areas:
      "trainerSearch"
      "trainerMap"
      "trainerCards";
  }
}

.gridTrainerSearchOnlineOnly {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas:
    "trainerSearch trainerCards trainerCards trainerCards"
    "trainerSearch trainerCards trainerCards trainerCards"
    "trainerSearch trainerCards trainerCards trainerCards"
    "trainerSearch trainerCards trainerCards trainerCards";
  margin-bottom: 20px;
}

.hideSection {
  display: none;
}

@media only screen and (max-width: 800px) {
  .gridTrainerSearchOnlineOnly {
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    grid-template-areas:
      "trainerSearch"
      "trainerCards";
  }
}

.gridTrainerSearchNoLocation {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas: "trainerSearch";
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .gridTrainerSearchNoLocation {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "trainerSearch";
    height: auto;
  }
}

.gridTrainerSearchWithMap img {
  width: 100%;
}

.gridTrainerSearch__col1__options {
  padding: 0 20px;
}

.gridTrainerSearch__col1 {
  grid-area: trainerSearch;
  width: 375px;
  overflow: scroll;
  margin: 0 auto;
}
.gridTrainerSearch__col2 {
  grid-area: trainerMap;
}

.gridTrainerSearch__col3 {
  grid-area: trainerCards;
}

@media only screen and (max-width: 800px) {
  .gridTrainerSearch__col3 {
    display: grid;
  }
}

.gridTrainerSearch_trainers {
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  gap: 20px;
  overflow: scroll;
  padding: 10px;
}

@media only screen and (max-width: 800px) {
  .gridTrainerSearch_trainers {
    display: flex;
    flex-direction: row;
  }
}

.trainerSearch_sub_options {
  grid-template-columns: repeat(1, 1fr);
}

.trainerSearch_sub_options__col1,
.trainerSearch_sub_options__col2 {
  border: 1px solid #dadada;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.trainerSearch_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  height: 50px;
  width: 100%;
  border: 2px solid var(--black-color);
  border-radius: 35px;
}

.trainerSearch_button_selected {
  background-color: #840000;
  color: var(--white-color);
}

.trainerSearch_button:hover {
  background: #840000;
  color: var(--white-color);
}

.trainerSearch_button i,
.trainerSearch_button i {
  margin-right: 10px;
}

.trainerSearch_sub_filters {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.trainerSearchFilterPopup_wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.trainerSearchFilterPopup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: var(--white-color);
  border-right: 3px solid var(--black-color);
  padding: 20px;
  z-index: 1000;
}

.trainerSearch_switchButton {
  display: flex;
  justify-content: flex-end;
}

.trainerSearch_addressSelectorDropDown {
  padding: 10px;
  display: grid;
  row-gap: 10px;
}

.trainerSearch_addressSelectorDropDown p,
.trainerSearch_addressSelectorDropDown input {
  margin: 0;
}

.traninterSearch_gridButton {
  display: grid;
  row-gap: 5px;
  margin-bottom: 10px;
}
