.telephoneNumberInput_options {
  display: grid;
  grid-auto-columns: 30% 70%;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.telephoneNumberInput_options input {
  margin-bottom: 0px;
}
