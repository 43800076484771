.exerciseCard{
   /*  display: grid;
    grid-template-areas: 
        "imgEx exCardTitle exCardTitle  exCardTitle  exCardTitle"
        "imgEx exCardMeta exCardMeta exCardMeta exCardLevel"
        "imgEx exCardMeta exCardMeta exCardMeta exCardLevel";
    grid-template-columns: repeat(5, 1fr); */
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    /* align-items: center; */
   /*  border: 2px solid var(--primary-color); */
    padding: 5px;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    text-align: center;
    background-color: var(--white-color);
}

.exerciseCard:hover {
    transition: all 1s ease;
    box-shadow: 5px 5px 20px 5px rgb(0 0 0 / 20%);
    transform: scale(1.1);
}

.exerciseCard__image{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.exerciseCard__image img{
    border-radius: 10px;
    object-fit: contain;
    width: 100%;
} 
/* .exerciseCard__title{
  
    height: 100%;
} */
/* .exerciseCard__meta{
    grid-area: exCardMeta;
    height: 100%;
} */
/* .exerciseCard__level{
    grid-area: exCardLevel;
    height: 100%;
} */

.exercise_tags {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;
}

.exercise_tags a{
    text-decoration: none;
}

.exercise_tags a:hover div{
    background-color: var(--red-color);
    color: var(--white-color);
}

.exercise_tags div{
    /* display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 20px; */
    border: 2px solid var(--red-color);
    border-radius: 20px;
    font-size: 10px;
    text-align: center;
    padding: 3px 10px 3px 10px;
    color: var(--white-color);
    text-transform: uppercase;
    font-weight: 600;
    background-color: var(--red-color);
    font-family: var(--font-secondary);
}

.exerciseCard__meta{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
}

.exerciseCard__meta div{
    border: 2px solid var(--red-color);
    border-radius: 20px;
    font-size: 10px;
    text-align: center;
    padding: 3px 10px 3px 10px;
    color: var(--white-color);
    text-transform: uppercase;
    font-weight: 600;
    background-color: var(--red-color);
    font-family: var(--font-secondary);
}