.productImageContainer {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

.lightBox-mainImage {
  display: flex;
  align-items: center;
}

.lightBox-mainImage img {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.lightBox-mainImage img:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.subPhotoIsMain {
  border: 2px solid var(--primary-color);
}
