.updateConfirmationPopUp {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.updateConfirmationPopUp__inner {
  max-width: 350px;
  background-color: var(--white-color);
  color: var(--black-color);
  width: 70%;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--primary-color);
}

.updateConfirmationPopUp__inner .title {
  color: var(--white-color);
}

.updateConfirmationPopUp__inner .btn:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .updateConfirmationPopUp__inner {
    width: 90%;
  }
}
