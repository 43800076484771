.feedbackContainer{
    max-height: 100vh;
}

.feedbackContainer_prevTicket{
    overflow: scroll;
}

.feedbackContainer_ticketContainer{
    padding: 0px 20px;
}

.feedbackPrevTickCard{
    padding: 10px;
    margin-bottom: 2px;
    cursor: pointer;
    background-color: var(--white-color);

    border-radius: 8px;
    border:  1px solid #e0e0e0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.feedbackPrevTickCard:hover{
    transition: all 500ms;
    box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -webkit-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -moz-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    transform: scale(1.1);
}

.commentCard{
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    border:  1px solid #e0e0e0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.current_ticket{
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    background-color: var(--white-color);
}

.fb_details{
    padding: 0 10px;
    margin-bottom: 10px;
}

.fb_details p{
    margin-bottom: 15px;
}

.fb_details_list{
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
}

.fb_details_item{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.fb_description{
    padding: 10px;
    margin-bottom: 10px;
    min-height: 200px;

    border-radius: 8px;
    border:  1px solid #e0e0e0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.fb_comment{
    padding: 10px;
    margin-bottom: 10px;
}