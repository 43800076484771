.explain-hover-contianer{
    position: relative;
    max-width: fit-content;
}

.explain-hover-contianer__content{
    position: absolute;
    top: 20px;
    left: 0px;
    background-color: var(--white-color);
    color: var(--black-color);
    min-width: 150px;
    max-width: 250px;
    /* height: 100px; */ 
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--color-9f0102);
    z-index: 2000;
}
.explain-hover-contianer__content p{
    margin-bottom: 0px;
}

.explain-icon{
    cursor: help;
}