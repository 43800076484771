.eventPicker {
  margin-bottom: 30px;
}

.eventPickerDates {
  margin-bottom: 20px;
}

.eventPickerDates {
  display: flex;
  flex-direction: column;
}

.eventPickerDates_start {
  margin-bottom: 10px;
}

/* .eventPickerDates_start,
.eventPickerDates_end {
  display: grid;
  grid-template-columns: 30% 70%;
  width: 50%;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
@media (max-width: 700px) {
  .eventPickerDates_start,
  .eventPickerDates_end {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
  } */
 /*  .eventPickerDates_start {
    margin-bottom: 10px;
  }
} */

.eventPickerDates_start input,
.eventPickerDates_end input {
  margin-bottom: 0;
}

.eventPickerDates_options {
  display: grid;
  grid-auto-flow: column;
  gap: 15px;
  overflow: scroll;
}

.eventPickerDates_options .secondary {
  margin-bottom: 0;
  font-size: 22px;
  white-space: nowrap;
}
