.grid3070{
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-areas: "col13070 col23070";
    margin-bottom: 20px;
    margin: 0 auto 20px auto;
    max-width: 1280px;
}

.grid3070__col1{
    grid-area: col13070;
}

.grid3070__col2{
    grid-area: col23070;
}

@media only screen and (max-width: 800px) {
    .grid3070{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 
                    "col13070"
                    "col23070";
    }
}