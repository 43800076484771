.accountSettings{

}
.accountSettings .grid3070__col1 {
    padding: 0px 40px 0px 0px;
}
.accountSettings .btn{
    margin-bottom: 5px;
    width: 100%;
}
.accountSettings li{
    margin-left: 0px;
}
.accountSettings ul{
    list-style: none;
}
.input8020{
    display: flex;
}
.input8020 input{
    width: 13px;
    margin-right: 10px;
}
