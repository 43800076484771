.controlPanel__goalsPopup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(250,250,250,0.96);
    overflow: scroll;
    z-index: 100;
}

.controlPanel__goalsPopup__inner{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
} 

.controlPanel__goalsPopup__inner__close{
     display: grid;
     justify-content: end;
     padding-top: 20px;
     padding-bottom: 0px;
}