
.cardLink{
  text-decoration: none;
  color: inherit;
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
}

.articleSideNav .articleCard{
  display: grid;
  grid-template-columns: 30% 70%;
}
.articleCard {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
   /*  border: 1px solid var(--primary-color); */
    border-radius: 10px;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }
.articleCard:hover{
    transition: all 500ms;
    box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -webkit-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -moz-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
}
.articleCard__image {
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  .articleCard__image img{
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  .articleCard__meta {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .articleFilter{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .articleFilter input,
  .articleFilter .btn,
  .articleFilter select{
    margin-bottom: 0px;
  }
  .articleFilter input{
    width: 300px;
  }

  @media only screen and (max-width: 600px) and (min-width: 0px) {
    .articleFilter{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .articleFilter input{
      width: 100%;
    }
  }
  