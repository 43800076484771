.searchInput {
  position: relative;
  margin-bottom: 20px;
}

.searchInput input {
  width: 100%;
  margin-bottom: 0px;
}

.searchInput__icon {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--primary-color);
  height: 36px;
  width: 32px;
  text-align: center;
  border-radius: 0px 6px 6px 0px;
  border: 2px solid var(--black-color);
  color: var(--white-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resultContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.resultContainer:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.resultContainer .btn,
.resultContainer p {
  margin-bottom: 0;
}

.text-decoration {
  text-decoration: none !important;
}
