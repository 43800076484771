header {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: var(--color-9f0102);
    grid-template-areas: " nav nav nav nav nav";
    line-height: 50px;
    margin-bottom: 30px;
  }
  .header__desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
    text-align: center;
    color: var(--white-color);
    padding-top: 5px;
    font-family: var(--font-primary);
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
  }
  .header__login {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas: "search search search logo logo logo logo  login login login";
    background-color: var(--white-color);
    color: var(--color-9f0102);
    padding: 10px;
  }
  .header__login .socialIcons a {
    color: var(--color-9f0102);
  }
  .header__login ul {
    display: inline-flex;
    list-style: none;
    margin: 0px;
  }
  .header__login ul li {
    margin-left: 5px;
  }
  .header__login__login {
    grid-area: login;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px;
    align-items: center;
    margin-right: 5px;
  }

  .loginUserIcon_burger img {
    width: 40px;
    height: 40px;
    border: 1px solid var(--black-color);
    border-radius: 50%;
    object-fit: cover;
}
.loginUserIcon img{
  width: 40px;
  height: 40px;
  border: 1px solid var(--black-color);
  border-radius: 50%;
  object-fit: contain;
}
  .header__login .btn {
    padding: 5px;
    margin-bottom: 0px;
    font-size: 24px;
  }
  
  .header__login__search {
    grid-area: search;
  }
  .header__login__logo {
    grid-area: logo;
    text-align: center;
  }
  .header__login__logo img {
    height: 50px;
  }
  .header__nav {
    grid-area: nav;
    align-self: center;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    position: relative;
  }
  .header__nav a {
    font-family: var(--font-primary);
    font-size: 26px;
    margin-right: 15px;
    text-align: center;
  }
  
  .header__nav__subNav {
    position: absolute;
    top: 171px;
    left: 0px;
    width: 100%;
    background-color: var(--white-color);
    z-index: 200;
    display: grid;
    grid-template-areas: "hNimage hNimage hNclinks hNclinks hNclinks hNclinks";
    grid-template-columns: repeat(6, 1fr);
    border-top: 1px solid var(--black-color);
    border-bottom: 1px solid var(--black-color);
  }
  
  .header__nav__subNav-enter {
    opacity: 0;
  }
  .header__nav__subNav-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .header__nav__subNav-exit {
    opacity: 1;
  }
  .header__nav__subNav-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  
  .header__nav__subNav__image {
    grid-area: hNimage;
    text-align: center;
    padding: 20px;
  }
  .header__nav__subNav__image img {
    max-height: 160px;
  }
  .header__nav__subNav__links {
    grid-area: hNclinks;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /*   justify-content: center;
      justify-self: center;
      justify-items: center; */
    align-items: center;
    /*   align-self: center; */
    /*   align-self: center; */
    text-decoration: underline;
  }
  .header__nav__subNav a {
    color: var(--black-color);
    font-size: 26px;
    text-decoration: underline;
  }
  .header__nav__subNav a:visited {
    color: var(--black-color);
  }
  .header__nav__subNav a:link {
    color: var(--black-color);
  }
  .header__nav__subNav a:hover {
    color: var(--red-color);
  }
  
  header a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 34px;
    transition: all .2s ease-in-out;
    color: var(--white-color);
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
  
  header a:visited {
    color: var(--white-color);
  }
  header a:link {
    color: var(--white-color);
  }
  header a:hover {
    text-decoration: underline;
    transform: scale(1.1);
  }
  .header__mobileNav {
    display: none;
  }
  .header__mobileNav i {
      cursor: pointer;
      margin-left: 20px;
      color: var(--white-color);
  }
  
  .messageIcon i{
      color: var(--black-color);
      font-size: 30px;
      cursor: pointer;
  }

  .messageIcon{
    position: relative;
  }
  .messageIcon__unseen{
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: var(--primary-color);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    color: var(--white-color);
  }

  @media only screen and (max-width: 800px) {
    .header__login {
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "search login login"
        "logo logo logo";
    }
    .header__nav {
      grid-area: nav;
      text-align: center;
      align-self: center;
      display: none;
      grid-template-columns: repeat(1, 1fr);
      position: relative;
    }
    .header__mobileNav {
      display: grid;
    }
    .header__mobileNav__dropDown {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .header__desktop {
      display: none;
    }
  }
  @media only screen and (max-width: 600px) {
    .header__login {
      grid-template-columns: repeat(5, 1fr);
      grid-template-areas:
        "search login login login login"
        "logo logo logo logo logo";
    }
    .header__login__login{
      gap: 6px;
    }
    .searchWording{
      display: none;
    }
    .header__login__logo img {
      height: 40px;
    }
  }