.cp_active_pt{
    max-width: 600px;
    margin-bottom: 20px;
}

.activePtSlider{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    gap: 10px;
    max-width: 90vw;
}

.addActivePtConfirmationPopUp{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.5);
    overflow: scroll;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.addActivePtConfirmationPopUp__inner{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-color: #19364c;
    width: 80%;
    height: 30%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
} 
.addActivePtConfirmationPopUp__inner h3,
.addActivePtConfirmationPopUp__inner p{
    color: var(--white-color);
}
.addActivePtConfirmationPopUp__inner__buttons{
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}
.addActivePtConfirmationPopUp__inner__close{
     display: grid;
     justify-content: end;
     padding-top: 20px;
     padding-bottom: 0px;
}