.bookingTypeContainer{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
    gap: 20px;
}

.bookingType_card{
    padding: 10px;
    border-radius: 8px;
    border:  1px solid #e0e0e0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.bookingType_card_cta{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.bookingType_card_cta .btn{
    margin: 0;
}