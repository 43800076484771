.hp__banner1{
   /*  background-color: #020b18; */
    grid-template-columns: 50% 50%;
    display: grid;
    min-height: 200px;
}

.hp__banner1 .socialIcons{
    justify-content: flex-start;
}

.hp__banner1__image img{
    width: 100%;
}

.hp_trackClientProgress__title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
}

.hp_trackClientProgress__title__right h2,
.hp_trackClientProgress__title h2{
    text-decoration: none;
}

.hp_trackClientProgress__title__right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    align-items: end;
    text-align: end;
}

.hp_trackClientProgress__title h2{
    color: var(--black-color);
   /*  font-size: 3vw; */
    text-decoration: none;
}

.hp_h1_font {
    font-size: 6vw;
}

.hp_h2_font{
    font-size: 3vw;
}

.hp_trackClientProgress__title ul{
    list-style: none;
    margin-left: 30px;
}

.hp_trackClientProgress__title li{
    text-transform: uppercase;
    font-family: var(--font-primary);
    font-size: 20px;
}

.hp_trackClientProgress__title li:before {
    content: "\f00c"; /* FontAwesome Unicode */
    font-family: FontAwesome;
    display: inline-block;
    margin-left: -1.3em; /* same as padding-left set on li */
    width: 1.3em; /* same as padding-left set on li */
}

.hp_trackClientProgress__image{
    padding: 30px;
}

.hp_trackClientProgress__image img{
    width: 100%;
}

.hp__banner__diagonal {
    width: 100%;
    height: 60px;
    background: linear-gradient(to right bottom, #020b18 50%, #19364c 50.3%);
}

.hp_trackClientProgress__diagonal {
    width: 100%;
    height: 60px;
    background: linear-gradient(to right bottom, #19364c 50%, var(--white-color) 50.3%);
}

.hp__findPt__diagonal {
    width: 100%;
    height: 60px;
    background: linear-gradient(to right bottom, #020b18 50%, #19364c 50.3%);
}

.hp__course__diagonal {
    width: 100%;
    height: 60px;
    background: linear-gradient(to right bottom, var(--white-color) 50%, var(--black-color) 50.3%);
}

.hp__end__diagonal {
    width: 100%;
    height: 60px;
    background: linear-gradient(to right bottom, var(--black-color) 50%, var(--white-color) 50.3%);
    margin-bottom: 50px;
}

@media only screen and (max-width: 600px) and (min-width: 0px) {
    .hp__banner1{
        grid-template-columns: repeat(1, 1fr);
        display: grid;
    }
    .hp__banner1 .socialIcons{
        justify-content: space-evenly;
    }
    .hp_trackClientProgress__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 30px;
        text-align: center;
    }
  /*   .hp__banner1__title h1 {
        font-size: 45px;
    } */
   /*  .hp__banner1__title h2{
        font-size: 24px;
    } */
  /*   .hp__banner1__title h2{
        font-size: 40px;
    } */

    .hp_h1_font {
        font-size: 45px;
    }
    .hp_h2_font{
        font-size: 24px;
    }
}
