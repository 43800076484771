.searchUsers {
  width: 60%;
  margin: 20px auto;
}

.grid3col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.grid3col .info_card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .grid3col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .searchUsers {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .grid3col {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .searchUsers {
    width: 90%;
  }
}
