.messagesCard {
  margin-bottom: 5px;
  display: grid;
  grid-template-areas: "imageMsg metaMsg metaMsg metaMsg";
  grid-template-columns: repeat(4, 1fr);
  cursor: pointer;
  overflow: hidden;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.messagesCard:hover {
  transition: all 500ms;
  box-shadow: 1px 1px 24px -1px rgba(0, 0, 0, 0.79);
  -webkit-box-shadow: 1px 1px 24px -1px rgba(0, 0, 0, 0.79);
  -moz-box-shadow: 1px 1px 24px -1px rgba(0, 0, 0, 0.79);
}

.messagesCard .truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Maximum number of lines to display */
  -webkit-box-orient: vertical;
}

.messageActive {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.messagesCard__seen {
  display: flex;
  flex-direction: row;
  gap: 5px;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 5px;
  border-radius: 10px;
  width: 65px;
  text-align: center;
  justify-content: center;
}

.messagesCard__image {
  grid-area: imageMsg;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messagesCard__image__file {
  width: 56px;
  height: 56px;
  object-fit: contain;
  border-radius: 50px;
}

.messagesCard__meta {
  grid-area: metaMsg;
  padding: 10px;
}

.messagesCard__meta__message {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  width: auto;
  margin-bottom: 5px;
}

.messagesCard__meta p,
.messagesCard__meta h4 {
  margin-bottom: 0px;
}
