.socialIcons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  justify-content: space-evenly;
  margin-bottom: 20px;
  font-size: 30px;
}

.facebook-icon {
  color: #38519a;
}

.youtube-icon {
  color: #f70000;
}

.instagram-icon {
  color: #9734bb;
}

.twitter-icon {
  color: #1ab0e4;
}

.socialSharing__buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
  gap: 5px;
  margin-bottom: 40px;
  max-width: 620px;
}

@media only screen and (max-width: 800px) and (min-width: 601px) {
  .socialSharing__buttons {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: 600px) and (min-width: 0px) {
  .socialSharing__buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
