.btnProcessing {
  cursor: pointer;
  border: 2px solid var(--black-color);
  padding: 5px;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  display: block;
  border-radius: 6px;
  font-family: var(--font-primary);
  font-size: 30px;
  transition: all 0.5s ease-in-out;
  color: var(--white-color);
/*   background-color: var(--primary-color); */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:global(button:disabled) {
  background-color: var(--color-grey);
  cursor: not-allowed;
  opacity: 0.6;
  color: var(--white-color);
  border: 1px solid var(--black-color);
}

.processingIcon{
  margin-left: 10px;
}

.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 13px;
}
.ldsEllipsis div {
  position: absolute;
  top: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--white-color);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: ldsEllipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: ldsEllipsis3 0.6s infinite;
}

@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}