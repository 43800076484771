.bookedSessions {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.bookedSessions__trainer__tile {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 15px;
  box-sizing: border-box;
}

.bookedSessions__trainer__tile h4 {
  text-transform: uppercase;
  margin-bottom: 0px;
}

/* .bookedSessions__card{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border: 1px solid rgba(0, 0, 0, 0.5);
    padding: 5px;
    box-sizing: border-box;
    align-items: center;
    margin-top: -1px;
} */

.ec-container {
  display: flex;
  flex-direction: column;
  margin: 5px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.ec-container .ec-container-wrapper {
  display: flex;
  flex-direction: row;
}

.ec-container-col1,
.ec-container-col2 {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
}

.ec-container h4 {
  margin-bottom: 3px;
}

.ec-container p {
  margin-bottom: 3px;
}

.ec-date,
.ec-payments,
.ec-location {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 5px 0;
  align-items: center;
}

.ec-date-duration {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 4px;
  border-radius: 5px;
}
.ec-date-duration p {
  text-transform: uppercase;
  font-family: var(--font-primary);
  margin: 0;
}
/* .ec-date-duration{
    box-shadow:inset 0px 1px 0px 0px #cf866c;
	background:linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
	background-color:#d0451b;
	border-radius:3px;
	display:inline-block;
	color: var(--white-color);
	font-family:var(--font-secondary);
	font-size:13px;
	padding:6px 24px;
	text-decoration:none;
	text-shadow:0px 1px 0px #854629;
} */

.ec-date-hour {
  font-weight: 800;
}

/* .ec-date::after,
.ec-location::after,
.ec-payments::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.2);
  } */

.ec-date-times {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px 0 0;
}

.ec-date-time {
  display: flex;
  flex-direction: column;
}

.ec-date-icon,
.ec-location-icon,
.ec-payments-icon {
  display: flex;
  font-size: 20px;
  width: 90px;
  justify-content: center;
  align-items: center;
}

.ec-location-meta,
.ec-payments-payment,
.ec-payments-payment-pay,
.ec-payments-payment-ref {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ec-container_cta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  margin: 0 0 20px;
}

.ec-container_cta .btn {
  margin-bottom: 0;
}
