form {
  display: flex;
  flex-direction: column;
  /*  padding: 30px 10px 30px 10px; */
  text-align: left;
}

input {
  padding: 8px 0px;
  margin-bottom: 10px;
  border-radius: 6px;
  padding-left: 5px;
  width: -webkit-fill-available;
  box-sizing: border-box;
}

input[type="radio"] {
  margin-right: 5px;
}

.inputNot100 input{
  width: 40px;
}

select {
  padding: 8px;
  margin-bottom: 10px;
}

select {
  margin-bottom: 15px;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 5px 15px;
  outline: 0;
  border: 2px solid var(--black-color);
  border-radius: 0px;
  background: var(--white-color);
  color: var(--black-color);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
}

.select select::-ms-expand {
  display: none;
}

.select select:hover,
.select select:focus {
  color: var(--white-color);
  background: var(--primary-color);
}

.select select:disabled {
  opacity: 0;
  pointer-events: none;
}

.select_arrow {
  position: absolute;
  top: 8px;
  right: 15px;
  width: 0px;
  height: 0px;
  border: solid #7b7b7b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
  border-color: var(--white-color);
}

.select select:disabled ~ .select_arrow {
  border-top-color: var(--red-color);
}

label {
  text-transform: uppercase;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: bold;
  display: grid;
  width: 100%;
  display: flex;
}

label a {
  margin-left: 5px;
}

form button {
  border: 1px solid var(--black-color);
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
  color: var(--white-color);
  text-decoration: none;
  text-align: center;
}

form textarea {
  border-radius: 6px;
  border: 2px solid var(--black-color);
  margin-bottom: 10px;
  padding: 8px 8px;
}

.close {
  cursor: pointer;
}

.vote_plus_disable,
.vote_minus_disable {
  color: rgba(0, 0, 0, 0.08);
}

.radioButtonTwoOptions{
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
}

.radioButtonTwoOptions label {
  display: flex;
  margin-right: 10px;
}
