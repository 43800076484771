.progress-sidenav {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 10px;
  box-sizing: border-box;
}

.progress-sidenav button {
  margin-bottom: 0px;
}

.noteCard {
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.noteCard h3,
.noteCard p {
  margin-bottom: 0px;
}

.noteCard__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.noteCard__header button {
  margin-bottom: 0px;
}

.noteCard__dropDown {
  padding: 10px 0px;
}

.noteCard__dropDown button {
  margin-bottom: 0px;
}

.noteCard__dropDown__body {
  margin-bottom: 20px;
}

.lineChart {
  margin-bottom: 20px;
}

.trackExerciseMax .spacing7030 {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 20px;
}

.weightProgressPrevCard {
  display: flex;
  justify-content: space-between;
  background-color: var(--white-color);
  margin-bottom: 5px;
  padding: 5px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.weightProgressPrevCard__buttons {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.weightProgressPrevCard form {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.weightProgressPrevCard form input {
  margin-bottom: 0px;
  max-width: 130px;
}

.weightProgressPrevCard button,
.weightProgressPrevCard p {
  margin-bottom: 0px;
}

@media only screen and (max-width: 800px) {
  .progress-sidenav {
    margin-bottom: 20px;
  }
}
