.contactContainer .socialIcons{
    font-size: 50px;
    max-width: 300px;
    margin: auto;
}

.faq-faq{
    margin-bottom: 40px;
}

.tab-faq{
    border: 1px solid var(--color-green);
    cursor: pointer;
    margin-bottom: 5px;
    padding: 5px;
}

.tab-faq h3:hover{
    color: var(--red-color);
}

.tab-faq h3{
    margin-bottom: 0px;
}