.tooltipTitleWrapper{
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.tooltipWrapper{
    position: relative;
}

.tooltip{
    cursor: pointer;
    border: 2px solid var(--black-color);
    padding: 3px;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: var(--black-color);
    height: 20px;
    width: 20px;
    text-decoration: none;
    list-style: none;
    font-size: 16px;
}

.tooltipText {
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: var(--white-color);
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: calc (100% - 30px); /* Position above the label */
    left: 50%;
    margin-left: -100px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    font-family: var(--font-secondary);
    font-size: 14px;
    text-transform: initial;
    padding: 10px;
    border: 2px solid var(--black-color);
  }

  .tooltip:hover + .tooltipText {
    visibility: visible;
    opacity: 1;
  }