.login-needed{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 30px 0px;
}
.login-needed h3{
    font-size: 30px;
}
.profileHeader{
    position: relative;
    height: 400px;
    width: 100%;
    margin-bottom: 20px;
    margin-top: -30px;
}
section.profileHeader img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 200px;
}
.profileHeader__heroBanner img{
    width: 100%;
    height: 100%;
}
.profileHeader__imageContainer{
    height: 230px;
    position: absolute;
    bottom: 0px;
    width: auto;
    margin: 0px auto;
    left: 10%;
}
.profileHeader__changeBannerImage{
    position: absolute;
    top: 10px;
    left: 20px;
}

.profileImageContainer{
    position: relative;
}

.profileImage{
  /*   position: absolute;
    bottom: 20px;
    left: 20px; */
   /*  border: 1px solid var(--black-color);
    border-radius: 20px; */
   /*  padding: 10px; */
    width: 200px;
    position: relative;
}

.profileImage_img{
    max-height: 200px;
    min-height: 200px;
    border: 1px solid var(--black-color);
}

.profileImage__editDrop{
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    background-color: var(--white-color);
    flex-direction: column;
    padding: 10px;
    border: 1px solid var(--black-color);
    border-radius: 10px;
    z-index: 10;
}
.profileImage_img{
    width: 100%;
    border-radius: 20px;
}
.profile_actions_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.profile_actions_button_wrapper{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.profileNavigation__meta{
    grid-area: profileNavigationMeta;
    min-height: 80px;
    display: flex;
    flex-direction: column;
}
.profileNavigation{
    background-color: var(--color-9f0102);
    padding: 10px 0px;
    margin-bottom: 20px;
}
.profileNavigation__buttons{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    list-style: none;
    align-items: center;
}
.profileNavigation__buttons .btn{
    margin-bottom: 0px;
    cursor: pointer;
    border: 2px solid var(--black-color);
    padding: 10px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    display: block;
    border-radius: 0px;
    font-family: var(--font-primary);
    font-size: 30px;
    width: 100%;
}
.profileNavigation__buttons li{
    width: 100%;
}

.contactListWrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.contactCard {
    display: grid;
    grid-template-columns: auto 1fr auto;
    height: 90px;
    border: 1px solid var(--black-color);
    border-radius: 15px;
    padding: 5px;
    margin-bottom: 10px;
    align-items: center;
    gap: 10px;
}

.contactCard:hover{
    transition: all 500ms;
    box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -webkit-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
    -moz-box-shadow: 1px 1px 24px -1px rgba(0,0,0,0.79);
}

.contactListWrapper a {
    text-decoration: none;
}
.contactCard h3,
.contactCard .delete{
    margin: 0;
}

.contactCard img {
    height: 79px;
    border-radius: 10px;
}

.uploadProfileImage_popup__outer{
    display: flex;
    top: 0;
    left: 0;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
.uploadProfileImage_popup__inner{
    width: 400px;
    max-height: 80vh;
    background-color: var(--white-color);
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid var(--primary-color);
    z-index: 50;
    display: flex;
    flex-direction: column;
    overflow: scroll;
} 
.profileTabs{
    display: flex;
    flex-direction: column;
}
@media only screen and (max-width: 800px) {
    .profileNavigation__buttons{
        grid-template-columns: repeat(1, 1fr);
    }
    .profileHeader__imageContainer{   
        left: 5%;
    }
}

