.postCode_wrapper {
  display: flex;
  flex-direction: row;
}

.postCodeInput_container {
  position: relative;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
}

.postCodeInput_input {
  background-color: #f2f2f2;
  border-radius: 0;
  height: 50px;
  border: 0;
  border: 1px solid var(--black-color);
  border-bottom: 3px solid var(--black-color);
  border-radius: 10px;
}

.postCodeInput_button {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 3px solid var(--black-color);
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
}

.postCodeInput_button:hover {
  color: var(--red-color);
}
