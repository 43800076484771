@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700");

.StripeElement {
  background-color: var(--white-color);
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--color-d8d8d8);
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  font-family: "Source Code Pro", monospace;
  font-size: 16px;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: 100%;
  height: 40px;
  height: auto;
  margin-bottom: 20px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.pay-button {
  background-color: #5469d4;
  color: var(--white-color);
  border: 0;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 24px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.pay-button:hover {
  background-color: #6772e5;
}

.payment-success {
  margin-top: 20px;
  color: #43ac6a;
  font-weight: bold;
}
