.companyHeader{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "companyHeaderImg companyHeaderImg companyHeaderIntro companyHeaderIntro companyHeaderIntro companyHeaderIntro";
    padding: 40px 0px;
}

.companyHeader__image{
    grid-area: companyHeaderImg;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.companyHeader__image img{
    width: 100%;
    max-width: 200px;
}

.companyHeader__intro{
    grid-area: companyHeaderIntro;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.companyHeader_titleEdit{
  display: grid;
  grid-template-columns: auto 30px;
}

.companyHeader_title{
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  width: 100%;
  white-space: nowrap;
}

.company-tabs {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.trainersContainer{
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow: scroll;
}

.companyPage__package {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin-bottom: 20px;
}

/* .companyPage__package__container {
  background-color: var(--white-color);
  padding: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  box-sizing: content-box;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
}

.companyPage__package__container p{
  margin-bottom: 10px;
}
 */
.companyOffersSection{
  background-color: #19364c;
  padding: 30px 0px;
  margin-bottom: 20px;
}

.companyOffersSection h2{
  color: var(--white-color);
  text-decoration: none;
}

.companyPage__offers {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow: scroll;
  margin-bottom: 20px;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.companyPage__offers p{
    margin-bottom: 5px;
}

.companyPage__offers__container {
    background-color: var(--white-color);
    padding: 20px;
    border: 5px dashed var(--primary-color);
    min-width: 210px;
    scroll-snap-align: start;
}

.companyPage__offers__container p{
  margin-bottom: 10px;
}

.editCompanyOfferPopup{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
}

.editCompanyOfferPopup__inner{
  display: flex;
  flex-direction: column;
  width: 50%;
  max-height: 80vh;
  background-color: var(--white-color);
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  overflow: scroll;
}

.reviews-filter{
  display: grid;
  grid-template-columns: 50% 40%;
  grid-gap: 10px;
  width: 250px;
  margin: 0 auto 20px;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .reviews-filter{
    grid-template-columns: 100%;
    width: 200px;
  }
}

.reviews-filter select,
.reviews-filter button {
  margin-bottom: 0px;
}

.company_classes{
  margin-bottom: 40px;
}
.company_classes h5{
  margin-bottom: 4px;
}
.company_classes_class_card{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--black-color);
  border-bottom: 1px solid var(--black-color);
  padding: 5px 0;
}
.company_classes_class_card:hover{
  background-color: rgba(0,0,0, 0.1);
}
.company_classes_class_card p,
.company_classes_class_card a,
.company_classes_class_card h4{
  margin-bottom: 0px;
}

.company_classes_class_card .primary{
  font-size: 18px;
}

.reviews-meta{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.companyPage__testimonials {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.companyPage__articles .info_card {
  width: 250px;
}

.companyMessageSection{
  background-color: #19364c;
  padding: 30px 0px;
}
.companyMessageSection h2{
  color: var(--white-color);
  text-decoration: none;
}
.companyMessageSection .center80{
  display: flex;
  flex-direction: column;
}

.editButtons i{
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  transition: all .2s ease-in-out;
  max-width: 20px;
    border: 1px solid red;
    border-radius: 50%;
    padding: 5px;
    justify-content: center;
    align-items: center;
}

.editButtons i:hover{
 color: var(--white-color);
 background-color:rgba(0,0,0,0.5);
}

@media only screen and (max-width: 800px) {
  .editCompanyOfferPopup__inner{
    width: 95%;
    max-height: 95vh;
    padding: 10px;
  }

  .companyHeader{
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: 
        "companyHeaderImg"
        "companyHeaderIntro";
    }

    .companyPage__offers__container{
      padding: 10px;
    }   
}
